import { SubmitOptions, useActionData, useSubmit } from "react-router-dom"
import { useEffect, useState } from "react"
import { SubmitTarget } from "react-router-dom/dist/dom"

type ActionData<T> = { data: T; error: undefined } | { data: undefined; error: Error } | undefined

const useMutationAction = <T extends SubmitTarget>(submitOptions: SubmitOptions) => {
  const submit = useSubmit()
  const mutate = (submitData: T) => submit(submitData, submitOptions)
  const data = useActionData() as ActionData<T>
  const [error, setError] = useState<Error>()
  const [successToast, setSuccessToast] = useState(false)
  const clearError = () => setError(undefined)

  useEffect(() => {
    if (data) {
      if (data.error) {
        setError(data.error)
        setSuccessToast(false)
      } else {
        setError(undefined)
        setSuccessToast(true)
        setTimeout(() => setSuccessToast(false), 10000)
      }
    }
  }, [data])

  return {
    data,
    error,
    mutate,
    clearError,
    successToast
  }
}

export default useMutationAction
